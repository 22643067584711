import * as React from 'react';

import { SiteSwitcher } from './site-switcher';
import { useApp } from './context';

import Upsell from 'public/icons/home-profile/upsell.svg';
import {componentsJson} from "~/constants/components";
interface Props {
  statusCode: number;
}

const MissingSites: React.VFC<Props> = ({ statusCode }) => {
  const { isWebView } = useApp();

  const { missingSitesText2, missingSitesText, missingSitesText3} = componentsJson.BillManagement.MissingSites

  return (
    <main className="flex flex-auto flex-col">
      {!isWebView ? (
        <div className="bg-offWhite-200 px-4 pt-[22px] pb-[11px] text-base font-semibold leading-none text-utility-accent">
          <div className="mx-auto flex w-full max-w-[958px] items-center justify-end">
            <SiteSwitcher />
          </div>
        </div>
      ) : null}
      <div className="mx-auto flex max-w-[600px] flex-auto flex-col items-center justify-center space-y-2 px-4 text-center">
        <Upsell className="mx-auto h-[200px] w-[200px]" />
        <div>
          <p className="pb-2 font-semibold">
            {missingSitesText}
          </p>
          <span>{missingSitesText2}</span>
          <p className="mt-5 text-xs text-gray-400">
            {missingSitesText3}: {statusCode}
          </p>
        </div>
      </div>
      <style jsx global>{`
        body {
          background: #fefefe;
        }
      `}</style>
    </main>
  );
};

export { MissingSites };
